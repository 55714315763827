import React from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import { LoadingProvider } from './components/Legacy/Commons/hooks/loading';
import { init } from './components/Legacy/translations';
import { ModalProvider } from 'styled-react-modal';
import { SpecialModalBackground } from './components/Legacy/Commons/SpecialModalBackground';
import AppRoutes from './routes/AppRoutes';
import { useLocation } from 'react-router-dom';
import useBackendNotification from './components/Legacy/Commons/hooks/useBackendNotifications';

init();

function App() {
  const location = useLocation();
  useBackendNotification();

  return (
    <ErrorBoundary key={location.pathname}>
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        <LoadingProvider>
          <AppRoutes />
        </LoadingProvider>
      </ModalProvider>
    </ErrorBoundary>
  );
}

export default App;
