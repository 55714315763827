import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import '@pinv/ui/index.css';
import 'App.css';
import './index.css';
import App from './App';
import { NotificationProvider } from './components/Legacy/Commons/hooks/Notifications';
import { UserLifecycleProvider } from './providers/UserLifecycleProvider';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <NotificationProvider>
        <BrowserRouter>
          <UserLifecycleProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </UserLifecycleProvider>
        </BrowserRouter>
      </NotificationProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
